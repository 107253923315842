import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

// Related
import Image from 'components/shared/Image'
import Window from 'components/mask/Window'

const DivMotion = styled(Link)`
  flex: 0 0 17.5%;
  transition: 300ms;
  text-decoration: none !important;

  svg {
    width: 100%;
    height: auto;
    border-top-left-radius: 130px;
    border-top-right-radius: 130px;
  }

  .window-border {
    visibility: hidden;
  }

  &.active {
    flex: 0 0 20%;

    .window-border {
      visibility: visible;
    }
  }

  @media (max-height: 950px) {
    flex: 0 0 15%;

    &.active {
      flex: 0 0 17.5%;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 991px) {
    max-width: 20%;
    min-width: 20%;
  }

  @media (max-width: 575px) {
    min-width: 40%;
  }
`

const Icon = styled(Image)`
  width: 118px;
  height: 118px;
  @media only screen and (min-width: 576px) and (max-width: 991px) {
    width: 90px;
    height: 90px;
  }

  @media (max-width: 575px) {
    width: 90px;
    height: 90px;
  }
`

const Box = styled.div`
  margin-top: -4rem;
`

const Blocks = ({ data }) => {
  const [active, setActive] = useState(2)

  return data.map(({ item }, index) => (
    <DivMotion
      to={item.link.url}
      className={`col px-3 d-flex flex-wrap flex-column justify-content-lg-end align-items-lg-center justify-content-start align-items-between mt-lg-0 mt-5 ${
        index === active && 'active'
      }`}
      key={item.link.title}
      onMouseEnter={() => setActive(index)}
    >
      <motion.div whileTap={{ scale: 0.9 }}>
        <Window
          imageUrl={
            item.image.url ? item.image.url : item.image.localFile.publicURL
          }
        />
      </motion.div>
      <Box>
        <motion.div whileTap={{ scale: 1.1 }}>
          <Icon src={item.icon.url} className="mb-2" />
        </motion.div>
        <p className="text-center font-family-secondary font-size-xm">
          {item.link.title}
        </p>
      </Box>
    </DivMotion>
  ))
}

export default Blocks
