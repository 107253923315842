import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ImageBackground from 'components/shared/ImageBackground'

const Hero = styled(ImageBackground)`
  @media (min-width: 992px) {
    height: 66vh;
  }

  @media (max-width: 991px) {
    /* min-height: 450px; */
  }
`

const Content = styled(ParseContent)`
  & h1 {
    font-size: ${(props) => props.theme.font.size['80']};
    line-height: ${(props) => props.theme.font.size['80']};
    color: ${(props) => props.theme.color.text.contrast};
    text-transform: uppercase;
    text-align: center;
    font-weight: 100;
    margin-bottom: 1.5rem;
  }

  & h2 {
    font-size: ${(props) => props.theme.font.size.xl};
    color: ${(props) => props.theme.color.text.light};
    font-weight: 100;
    text-transform: none;
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size.xm};
    }
  }
`

const HeroDefault = ({ fields }) => (
  <Hero src={fields.image} aboveFold>
    <div className="container py-4">
      <div className="d-flex justify-content-center py-5">
        <Content content={fields.description} />
      </div>
    </div>
  </Hero>
)

export default HeroDefault
