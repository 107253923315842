import React from 'react'
import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useMedia } from 'react-use'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import Blocks from 'components/elements/Blocks'

// Shared
import Image from 'components/shared/Image'
import ImageBackground from 'components/shared/ImageBackground'
import Content from 'components/shared/Content'
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'
import { NewsTitle } from '../components/elements/NewsTitle'

const BlockSection = styled.section`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  .row {
    min-height: 700px;

    @media (max-height: 950px) {
      min-height: 650px;
    }
  }

  @media (min-width: 992px) {
    margin-top: -25rem;
  }
`

const BlockScrollContainer = styled(ScrollContainer)``

const Title = styled(Content)`
  & h2 {
    font-size: ${(props) => props.theme.font.size['60']};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.family.secondaryThin};

    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[40]};
    }

    span {
      font-family: ${({ theme }) => theme.font.family.main};
      color: ${({ theme }) => theme.color.text.contrast};
    }
  }
`

const ImageCol = styled.div`
  @media (max-width: 991px) {
    order: 2;
  }
`

const Gemeente = styled(ImageBackground)`
  height: 450px;

  h3 {
    font-family: ${({ theme }) => theme.font.family.secondaryThin};
    color: ${({ theme }) => theme.color.text.contrast};
    text-transform: uppercase;

    @media (max-width: 768px) {
      background: rgba(255, 255, 255, 0.9);
      padding: 2rem 3rem;
    }

    strong {
      font-family: ${({ theme }) => theme.font.family.secondary};
    }
  }
  @media (max-width: 575px) {
    height: 250px;
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const isMobile = useMedia('(max-width: 575px)')

  const container = React.useRef(null)

  // const [mobileBlocks, setMobileBlocks] = React.useState([])

  React.useEffect(() => {
    // const array = [...acf.blocks]

    // const item = array.splice(2, 1)
    // array.unshift(item[0])

    // setMobileBlocks(array)

    const element = container.current

    if (element && isMobile) {
      // No feat settimeout is here
      setTimeout(() => {
        element.scrollLeft = element.offsetWidth / 2
      }, 150)
    }
  }, [container, isMobile])

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <HeroDefault fields={acf.banner} />

      <BlockSection className="d-none d-lg-block">
        <div className="row justify-content-center py-5">
          <Blocks data={acf.blocks} />
        </div>
      </BlockSection>

      <div className="position-relative w-100" style={{ overflow: 'hidden' }}>
        <BlockScrollContainer
          className="d-flex d-lg-none w-100 h-100"
          horizontal={true}
          innerRef={container}
        >
          <Blocks
            data={acf.blocks}
            className="d-flex h-100 w-100 justify-content-center align-items-between"
          />
        </BlockScrollContainer>
      </div>

      <section className="mb-5">
        <div className="container">
          <div className="row py-3 py-lg-5 mb-5 mb-lg-0">
            <ImageCol className="col-lg-5">
              <Image src={acf.who_we_are.image} />
            </ImageCol>
            <div className="col-lg-7 pl-lg-5 pt-lg-4 mb-5 mb-lg-0">
              <Title content={acf.who_we_are.title} />
              <Content content={acf.who_we_are.description} />
              <ButtonDefault className="mt-4" to={acf.who_we_are.link.url}>
                {acf.who_we_are.link.title}
              </ButtonDefault>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Gemeente className="my-5 py-md-5" src={acf.quote.background}>
          <div className="container h-100 d-flex justify-content-end align-items-md-center align-items-end">
            <ParseContent content={acf.quote.description} />
          </div>
        </Gemeente>
      </section>

      <section>
        <div className="container py-lg-5">
          <div className="row pb-5 align-items-center">
            <div className="col-sm-6 col-12 d-flex align-items-center">
              <NewsTitle content={acf.news.title} />
            </div>
            <div className="col-sm-6 col-12 d-flex justify-content-end">
              <ButtonDefault to={acf.news.link.url}>
                {acf.news.link.title}
              </ButtonDefault>
            </div>
          </div>

          <Blogik
            settings={{
              limit: 3,
            }}
          >
            <BlogConsumer>
              {({ hasPosts, posts }) => {
                return (
                  <div>
                    {hasPosts && <Blog posts={posts} />}

                    {!hasPosts && <ParseContent content="Geen resultaten" />}
                  </div>
                )
              }}
            </BlogConsumer>
          </Blogik>
        </div>
      </section>
    </Layout>
  )
}

export default HomeTemplate
